import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  LinearProgress,
  TextField,
  Typography,
  Tooltip,
  Snackbar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Formik, Form, ErrorMessage } from "formik";
import * as yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirm } from "material-ui-confirm";
import { uploadData } from "aws-amplify/storage";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

// import IOSSwitch from "../Components/IOSSwitch";
import FormField from "../assets/theme/overrides/FormField";
import { setAgentList } from "../redux/reducer";
import { AIModelList } from "../Components/AIModelSelect";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function AddAgent({ onSuccess, editData, onBack }) {
  const confirm = useConfirm();
  // const dispatch = useDispatch();

  const [editFileArr, setEditFileArr] = useState([]);
  const [delFileIds, setDelFileIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUploadCount, setFileUploadCount] = useState(-1);
  const [fileUploadProgress, setFileUploadProgress] = useState(0);
  const [isFileLoaded, setIsFileLoaded] = useState(false);

  const user = useSelector((state) => state.user);
  const isEdit = Boolean(editData);

  useEffect(() => {
    if (editData?.hasFile) {
      setIsFileLoaded(true);
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/agent/getFiles/${editData?.id}`
        )
        .then(({ data }) => {
          setEditFileArr(data);
          setIsFileLoaded(false);
        })
        .catch((e) => {
          console.log(e);
          setIsFileLoaded(false);
        });
    }
  }, [editData]);

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("agentName", data?.agentName);
      formData.append("systemPrompt", data?.systemPrompt);
      formData.append("isWebTool", data?.isWebTool);
      formData.append("agentLabel", data?.agentLabel);
      formData.append("agentText", data?.agentText);
      formData.append("AImodel", data.AImodel);
      formData.append(
        "AIprovider",
        AIModelList.find((it) => it.model === data.AImodel).provider
      );
      formData.append("userId", user.sub);
      formData.append("email", user.email);
      formData.append("hasFile", Boolean(data.files.length));
      formData.append("delFileIds", JSON.stringify(delFileIds));

      if (data.files.length > 0) setFileUploadCount(0);
      for (const file of data?.files) {
        await uploadData({
          path: `public/agentFiles/${user.sub}/${data?.agentName}/${file.name}`,
          data: file,
          options: {
            contentType: file.type,
            onProgress: ({ transferredBytes, totalBytes }) => {
              if (totalBytes) {
                setFileUploadProgress(
                  Math.round((transferredBytes / totalBytes) * 100)
                );
              }
            },
          },
        }).result;
        setFileUploadCount((prev) => prev + 1);
      }

      if (isEdit) {
        formData.append("id", editData.id);
      }

      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/${
          editData ? "updateAgent" : "addAgent"
        }`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setIsLoading(false);
      toast.success("Agent added successfully");
      onSuccess();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      toast.error("Something went wrong");
      setFileUploadCount(0);
      setFileUploadProgress(0);
    }
  };
  // console.log(AIModelList.find((it) => it.model === editData?.AImodel));

  return (
    <Container>
      <Box
        sx={{
          height: "calc(100vh - 50px)",
          overflow: "auto",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: { xs: 10, xl: 5 },
          }}
        >
          <ArrowBackIcon onClick={onBack} />
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            {isEdit ? "Edit" : `Add New`} Agent
          </Typography>
        </Box>
        <Formik
          initialValues={{
            agentName: editData?.agentName || "",
            systemPrompt: editData?.systemPrompt || "",
            agentLabel: editData?.agentLabel || "",
            agentText: editData?.agentText || "",
            AImodel: editData?.AImodel || "",
            isWebTool: editData?.isWebTool === "false" ? false : true,
            files: [],
          }}
          validationSchema={yup.object().shape({
            agentName: yup.string().required("Agent tName is required"),
            systemPrompt: yup.string().required("System Prompt is required"),
            agentLabel: yup.string().required("Agent Label is required"),
            agentText: yup.string().required("Agent Text is required"),
            AImodel: yup.string().required("AI model is required"),
            isWebTool: yup.boolean().required("Web tool is required"),
            files: yup.mixed(),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, handleBlur }) => (
            <Form>
              <Box>
                <FormField label="Agent Name" name="agentName" type="text" />
              </Box>
              <Box sx={{ mt: 3 }}>
                <FormField
                  multiline
                  minRows={5}
                  label="System Prompt"
                  name="systemPrompt"
                  type="text"
                />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormField label="Agent Label" name="agentLabel" type="text" />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormField label="Agent Text" name="agentText" type="text" />
              </Box>
              <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
                <Typography sx={{ mr: 2 }}>AI model:-</Typography>
                <FormControl>
                  <InputLabel id="simple-select-label">
                    Select AI model
                  </InputLabel>
                  <Select
                    labelId="simple-select-label"
                    label="Select AI model"
                    sx={{ width: 200 }}
                    value={values.AImodel}
                    onChange={(e) => setFieldValue("AImodel", e.target.value)}
                    disabled={isEdit}
                    onBlur={handleBlur}
                  >
                    {AIModelList.map((item, i) => (
                      <MenuItem value={item.model} key={i}>
                        <span>{item.name}</span>
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage name="AImodel" component="p" />
                </FormControl>
              </Box>
              <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                <Box sx={{ width: "100%" }}>
                  <TextField
                    type="file"
                    onChange={(e) => setFieldValue("files", e.target.files)}
                    fullWidth
                    slotProps={{
                      htmlInput: {
                        multiple: "multiple",
                      },
                    }}
                    // disabled={isEdit}
                  />
                  {isFileLoaded && <CircularProgress sx={{ mt: 2 }} />}
                  {fileUploadCount > 0 && (
                    <Box sx={{ mt: 3 }}>
                      <Typography mb={1}>
                        {`Successfully uploaded Files ${fileUploadCount} out of ${values.files.length}`}
                      </Typography>
                      {fileUploadCount === values.files.length ? (
                        <Typography mb={1}>
                          {/* Files uploaded successfully! Creating your agent now... */}
                          <LinearProgress sx={{ width: "100%" }} />
                        </Typography>
                      ) : (
                        <Box>
                          {`Progress of file:- ${values.files[fileUploadCount]?.name}`}
                          <LinearProgressWithLabel value={fileUploadProgress} />
                        </Box>
                      )}
                    </Box>
                  )}
                  {editFileArr.length > 0 && (
                    <Box sx={{ overflow: "auto", maxHeight: 200, mt: 2 }}>
                      {editFileArr.map((item) => (
                        <Box
                          sx={{ display: "flex", alignItems: "center", mt: 2 }}
                        >
                          <Typography>{item.filename}</Typography>
                          <DeleteIcon
                            sx={{ ml: 2 }}
                            onClick={() =>
                              confirm({
                                description: "You can not undo this action.",
                              }).then(() => {
                                setDelFileIds([...delFileIds, item.fileId]);
                                setEditFileArr(
                                  editFileArr.filter(
                                    (it) => it.fileId !== item.fileId
                                  )
                                );
                              })
                            }
                          />
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
              </Box>

              {/* <Box sx={{ mt: 4 }}>
              <FormControlLabel
                control={
                  <IOSSwitch
                    sx={{ ml: 2 }}
                    onChange={(e) =>
                      setFieldValue("isWebTool", e.target.checked)
                    }
                    checked={values.isWebTool}
                    onBlur={handleBlur}
                    name="isWebTool"
                  />
                }
                label="Web search tool"
                labelPlacement="start"
                sx={{ ml: 0 }}
              />
              <ErrorMessage name="isWebTool" component="div" />
            </Box> */}

              <Box
                sx={{
                  mt: 4,
                  justifyContent: "center",
                  display: "flex",
                  paddingBottom: 2,
                }}
              >
                <LoadingButton
                  variant="contained"
                  size="large"
                  sx={{
                    width: { xs: "50%", md: "30%" },
                  }}
                  type="submit"
                  disabled={isLoading}
                  loading={isLoading}
                >
                  {isEdit ? "Save Agent" : "Add Agent"}
                </LoadingButton>
              </Box>
            </Form>
          )}
        </Formik>
        <Box />
      </Box>
    </Container>
  );
}

const ManageAgent = () => {
  const [editData, setEditData] = useState(false);
  const [deletingId, setDeletingId] = useState(null);
  const [isAddAgent, setIsAddAgent] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const agentList = useSelector((state) => state.agentList);

  // Add state for copy feedback
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [copiedText, setCopiedText] = useState("");

  // Function to copy text to clipboard
  const copyToClipboard = (text, label) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopiedText(`${label} copied to clipboard`);
        setCopySnackbarOpen(true);
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.error("Failed to copy to clipboard");
      });
  };

  // Function to handle snackbar close
  const handleSnackbarClose = () => {
    setCopySnackbarOpen(false);
  };

  const handleDelete = async (id) => {
    setDeletingId(id);
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/deleteAgent`, {
        id: id,
      });
      await fetchData();
    } catch (error) {
      console.error("Delete failed:", error);
    } finally {
      setDeletingId(null);
    }
  };

  useEffect(() => {
    if (state?.isAddAgent) setIsAddAgent(state?.isAddAgent);
  }, [state]);

  const user = useSelector((state) => state.user);

  const columns = [
    {
      field: "agentName",
      headerName: "Agent Name",
      flex: 0.8,
      minWidth: 120,
      maxWidth: 300,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.value}
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row selection
              copyToClipboard(params.value, "Agent Name");
            }}
            sx={{ opacity: 0.5, "&:hover": { opacity: 1 } }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "userId",
      headerName: "User",
      flex: 1.5,
      minWidth: 180,
      maxWidth: 250,
      renderCell: (params) => {
        // const userName = params.row.userName || 'Unknown User';
        const userEmail = params.row.email || "No Email";
        const userId = params.row.userId || "";
        return (
          <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
            <Tooltip
              title={
                <Typography style={{ fontSize: "16px" }}>
                  User ID: {userId}
                </Typography>
              }
              placement="top-start"
            >
              <Box
                sx={{
                  flexGrow: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  cursor: "pointer",
                }}
              >
                {userEmail}
              </Box>
            </Tooltip>
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Prevent row selection
                copyToClipboard(userId, "User ID");
              }}
              sx={{ opacity: 0.5, "&:hover": { opacity: 1 } }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      },
    },
    {
      field: "systemPrompt",
      headerName: "System Prompt",
      flex: 2,
      minWidth: 200,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Tooltip
            title={
              <Typography style={{ fontSize: "16px" }}>
                {params.value}
              </Typography>
            }
            placement="top-start"
          >
            <Box
              sx={{
                flexGrow: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {params.value}
            </Box>
          </Tooltip>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row selection
              copyToClipboard(params.value, "System Prompt");
            }}
            sx={{ opacity: 0.5, "&:hover": { opacity: 1 } }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "fileCount",
      headerName: "File Count",
      flex: 0.5,
      minWidth: 80,
      maxWidth: 100,
      renderCell: (params) => {
        // Safely access the length of fileIds
        const fileCount = Array.isArray(params.row.fileIds)
          ? params.row.fileIds.length
          : "N/A";
        return (
          <Tooltip
            title={`${fileCount} file${fileCount !== 1 ? "s" : ""} loaded`}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                fontWeight: fileCount > 0 ? "bold" : "normal",
                color: fileCount > 0 ? "primary.main" : "text.secondary",
              }}
            >
              {fileCount}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "AImodel",
      headerName: "AI model",
      flex: 0.8,
      minWidth: 120,
      maxWidth: 300,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
          <Box
            sx={{
              flexGrow: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.value}
          </Box>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent row selection
              copyToClipboard(params.value, "Agent Name");
            }}
            sx={{ opacity: 0.5, "&:hover": { opacity: 1 } }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "isWebTool",
      headerName: "Tools",
      flex: 0.5,
      minWidth: 80,
      maxWidth: 100,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {(params.value === true || params.value === "true") && (
              <Tooltip title="Web Search Tool Enabled">
                <TravelExploreIcon style={{ fontSize: 32 }} />
              </Tooltip>
            )}
          </div>
        );
      },
    },

    {
      field: "updatedAt",
      headerName: "Last Edited",
      flex: 0.8,
      minWidth: 130,
      maxWidth: 250,
      renderCell: (params) => {
        // Get the date value - adjust based on your actual data structure
        const dateValue =
          params.value || params.row.updatedAt || params.row.lastEdited;
        // If no date is available
        if (!dateValue) {
          return "N/A";
        }
        // Format the date
        let formattedDate;
        try {
          const date = new Date(dateValue);
          // Check if date is valid
          if (isNaN(date.getTime())) {
            return "Invalid date";
          }
          // Format as relative time if recent (within last 7 days)
          const now = new Date();
          const diffDays = Math.floor((now - date) / (1000 * 60 * 60 * 24));

          if (diffDays < 7) {
            // For recent dates, show relative time
            if (diffDays === 0) {
              // Today - show time
              formattedDate = `Today at ${date.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}`;
            } else if (diffDays === 1) {
              formattedDate = "Yesterday";
            } else {
              formattedDate = `${diffDays} days ago`;
            }
          } else {
            // For older dates, show date
            formattedDate = date.toLocaleDateString([], {
              year: "numeric",
              month: "short",
              day: "numeric",
            });
          }
        } catch (error) {
          console.error("Error formatting date:", error);
          formattedDate = String(dateValue);
        }

        return (
          <Tooltip title={new Date(dateValue).toLocaleString()}>
            <Typography>{formattedDate}</Typography>
          </Tooltip>
        );
      },
    },
    {
      headerName: "Actions",
      field: "Add",
      filterable: false,
      sortable: false,

      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                width: "100%",
              }}
            >
              <EditIcon
                onClick={() => {
                  setIsAddAgent(true);
                  setEditData(params.row);
                }}
                sx={{
                  cursor: "pointer",
                  fontSize: { xs: "18px", sm: "24px" },
                }}
              />
              {user?.sub === params.row.userId && (
                <>
                  {deletingId === params.row.id ? (
                    <CircularProgress size={20} sx={{ ml: { xs: 1, sm: 3 } }} />
                  ) : (
                    <DeleteIcon
                      onClick={() => handleDelete(params.row.id)}
                      sx={{
                        ml: { xs: 1, sm: 3 },
                        cursor: "pointer",
                        fontSize: { xs: "18px", sm: "24px" },
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </>
        );
      },
    },
  ];

  const fetchData = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/agentList/${user.userRole}/${user.email}`
      )
      .then(({ data }) => {
        dispatch(setAgentList(data));
        // console.log("All Agents:", JSON.stringify(data, null, 2)); // Pretty print with 2 spaces for indentation
      })
      .catch((err) => {
        console.error("Error fetching agent data:", err);
        toast.error("Failed to load agents. Please try again later.");
      });
  };

  useEffect(() => {
    if (user?.sub) fetchData();
  }, [user]);

  const onBack = () => {
    if (isAddAgent) {
      setIsAddAgent(false);
    } else {
      navigate("/dashboard");
    }
  };

  return (
    <Box sx={{ m: { xs: 1, sm: 2, md: 5, xl: 10 } }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{ textAlign: "center", width: "100%", mb: 2 }}
      >
        Manage Agents
      </Typography>

      {!isAddAgent ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setEditData(null);
                setIsAddAgent(true);
              }}
            >
              Add Agent
            </Button>
          </Box>

          <Box sx={{ height: "75%", width: "100%" }}>
            <DataGrid
              rows={agentList || []}
              columns={columns}
              pageSize={20}
              rowsPerPageOptions={[25, 50, 100]}
              disableSelectionOnClick
              sx={{
                "& .MuiDataGrid-columnHeader": {
                  fontWeight: "bold",
                },
                "& .MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "bold",
                },
                width: "100%",
                "& .MuiDataGrid-main": {
                  width: "100%",
                },
              }}
            />
          </Box>

          <Snackbar
            open={copySnackbarOpen}
            autoHideDuration={3000}
            onClose={handleSnackbarClose}
            message={copiedText}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          />
        </>
      ) : (
        <AddAgent
          onSuccess={() => {
            setIsAddAgent(false);
            fetchData();
          }}
          editData={editData}
          onBack={() => setIsAddAgent(false)}
        />
      )}
    </Box>
  );
};

export default ManageAgent;
