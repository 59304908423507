import {
  Avatar,
  Box,
  IconButton,
  TextField,
  Typography,
  LinearProgress,
} from "@mui/material";
import PromptHolder from "./PromptHolder";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useState, useEffect } from "react";
import {
  setDrawer,
  setIsAgentDropdownOpen,
  setIsDashboardDropdownOpen,
  setIsTypingFN,
} from "../../redux/reducer";
import { useDispatch, useSelector } from "react-redux";
import Send from "@mui/icons-material/Send";
import StopIcon from "@mui/icons-material/Stop";
import MicIcon from "@mui/icons-material/Mic";
import { Icon } from "@iconify/react";
import { useLocation } from "react-router-dom";
import theme from "../../muiTheme";
// import websearching from "../../assets/Images/websearching.png";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ChatLoader = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const increment = 3.33;
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress >= 100) {
          return 100;
        }
        return Math.min(oldProgress + increment, 100);
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        py: { xs: 2, sx: 2, md: 3 },
        px: { xs: 2, sx: 3, md: 4 },
        borderRight: 0,
        borderLeft: 0,
        width: "90%",
        maxWidth: {
          xs: "450px",
          sm: "540px",
          md: "800px",
        },
        gap: "10px",
        margin: "auto",
      }}
    >
      <Typography variant="body2">Thinking...</Typography>
      <Box sx={{ width: "100%" }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
      {/* <img
        src={websearching}
        alt="Web Searching"
        style={{
          borderRadius: "50%",
          width: 35,
          height: 35,
          objectFit: "cover",
          animation: "pulse 1.5s infinite",
        }}
      />
      <span
        style={{
          marginLeft: 10,
          fontSize: "1rem",
          fontWeight: 500,
          animation: "pulse-text 1.5s infinite",
        }}
      >
        Searching the web...
      </span>{" "} */}
    </Box>
  );
};

const ChatHeader = ({ matchingData }) => {
  const myFiles = useSelector((state) => state.myFiles);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const threadId = queryParams.get("threadId");

  return (
    <>
      {myFiles.length > 0 ? (
        <Box
          sx={{
            p: { xs: "0px 0px", md: "0px 24px" },
            backgroundColor: (theme) => theme.palette.grey.gray_100,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: { xs: "10px", sx: "15px", md: "20px" },
              py: { xs: 2, sx: 2, md: 1.5 },
              px: { xs: 2, sx: 3, md: 1.5 },
              borderRadius: "8px",
              maxWidth: {
                xs: "450px",
                sm: "540px",
                md: "800px",
              },
              margin: "0 auto",
            }}
          >
            <Avatar
              alt="John Doe"
              sx={{
                width: { xs: "25px", md: "25px" },
                height: { xs: "25px", md: "25px" },
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                borderRadius: "4px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              <PersonOutlineOutlinedIcon />
            </Avatar>

            <Box display="flex" alignItems="center">
              <InsertDriveFileIcon
                sx={{
                  width: "20px",
                  height: "20px",
                  color: "white",
                  backgroundColor: "#8E8E9F",
                  borderRadius: "4px",
                  padding: "3px",
                }}
              />
              <Typography ml={1} variant="body1">
                {myFiles.map((item) => item?.path || item?.name).join(" , ")}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        matchingData?.length === 0 && !threadId && <PromptHolder />
      )}
    </>
  );
};

const ChatTextField = ({
  values,
  setValues,
  setIsVoiceAgent,
  loading,
  handleStop,
  handleSendMessage,
  handleChange,
  autoScroll,
}) => {
  const [colorFocus, setColorFocus] = useState(false);
  const dispatch = useDispatch();
  const typeOfAg = useSelector((state) => state.typeOfAg);

  const [isFocused, setIsFocused] = useState(false);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }

    if (isFocused) {
      if (e.ctrlKey && e.key === "f") {
        e.preventDefault();
        handleChange(e, 1);
      } else if (e.ctrlKey && e.key === "a") {
        e.preventDefault();
        dispatch(setDrawer(true));
        setTimeout(() => {
          dispatch(setIsAgentDropdownOpen(true));
        }, 500);
      } else if (e.ctrlKey && e.key === "m") {
        e.preventDefault();
        dispatch(setIsDashboardDropdownOpen(true));
      }
    }
  };
  const isDisabledFile = typeOfAg === "Open Deep Research";

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        maxWidth: { xs: "450px", sm: "540px", md: "800px" },
        margin: "0 auto",
        width: "100%",
      }}
    >
      <TextField
        variant="outlined"
        multiline
        minRows={1}
        maxRows={3}
        fullWidth
        value={values?.query}
        onChange={(e) => {
          setValues({ ...values, query: e.target.value });
          dispatch(setIsTypingFN(true));
        }}
        onKeyDown={handleKeyPress}
        onBlur={() => {
          dispatch(setIsTypingFN(false));
          setColorFocus(false);
        }}
        onFocus={() => {
          setColorFocus(true);
          setIsFocused(true);
        }}
        placeholder="Type your message..."
        sx={{
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none", // Remove the border
          },
          "& .MuiOutlinedInput-input": {
            border: "1px solid rgba(0, 0, 0, 0.03)",
            "-webkit-appearance": "none",
            boxShadow:
              "0 0 transparent,0 0 transparent,0 0 15px rgba(0,0,0,.1)",
            borderRadius: "8px",
            padding: "9px 12px",
          },

          "& .MuiOutlinedInput-input:focus": {
            border:
              values.query.length > 0
                ? `1px solid ${theme.palette.primary.main} `
                : "",
          },
          "& .MuiOutlinedInput-input:hover": {
            border: `1px solid ${theme.palette.primary.main} `,
          },
          "& .MuiOutlinedInput-root": {
            p: {
              xs: "16px 4px 16px 0px",
              md: "16px 0px",
              lg: "24px 0px",
            },
          },
        }}
        slotProps={{
          input: {
            startAdornment: (
              <IconButton
                onClick={(e) => {
                  handleChange(e, 1);
                }}
                disabled={isDisabledFile}
                sx={{
                  mr: { xs: 1, sm: 1, md: 1 },
                  cursor: "pointer",
                }}
              >
                <Icon
                  icon="material-symbols:folder-open-outline"
                  style={{
                    color: isDisabledFile ? "gray" : "black",
                  }}
                />
              </IconButton>
            ),
            endAdornment: (
              <>
                {colorFocus || values?.query || values.remote_url ? (
                  <IconButton
                    onClick={() =>
                      loading ? handleStop() : handleSendMessage()
                    }
                    onFocus={() => setColorFocus(false)}
                    type="submit"
                    sx={{ ml: { xs: 1, sm: 1, md: 1 } }}
                  >
                    {loading ? (
                      <StopIcon />
                    ) : (
                      <Send
                        sx={{
                          color: colorFocus ? theme.palette.primary.main : "",
                        }}
                        onClick={() => autoScroll()}
                      />
                    )}
                  </IconButton>
                ) : (
                  <IconButton
                    sx={{
                      ml: { xs: 1, sm: 1, md: 1 },
                      color: "rgba(0, 0, 0, 0.54)",
                    }}
                    onClick={() => {
                      setIsVoiceAgent(true);
                    }}
                  >
                    <MicIcon
                      sx={{
                        fontSize: "26px",
                      }}
                    />
                  </IconButton>
                )}
              </>
            ),
          },
        }}
      />
    </Box>
  );
};

export { ChatHeader, ChatLoader, ChatTextField };
