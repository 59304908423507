import React from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Typography, Box, ListItemIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
// import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { defaultAgentList } from "../Sidebar";

const MuiListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: "10px",
  marginRight: "10px",
}));

const PromptHolder = () => {
  const typeOfAg = useSelector((state) => state.typeOfAg);
  const agentList = useSelector((state) => state.agentList);
  const user = useSelector((state) => state.user);

  const name = user?.given_name?.split(" ")?.[0];
  const formattedName =
    name?.[0]?.toUpperCase() + name?.slice(1)?.toLowerCase();

  const currentHour = new Date().getHours();

  let greeting;
  if (currentHour >= 5 && currentHour < 12) {
    greeting = "Morning";
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = "Afternoon";
  } else {
    greeting = "Evening";
  }

  return (
    <Box
      style={{ margin: "auto" }}
      sx={{
        mx: { xs: 0, sm: 3, md: 4 },
        maxWidth: {
          xs: "350px",
          sm: "540px",
          md: "800px",
        },
        // paddingBottom: { xs: "17vh", md: 0 },
        paddingBottom: { xs: "0vh", md: 0 },
      }}
    >
      <Box>
        <Typography
          variant="body1"
          sx={{
            color: (theme) => theme.palette.grey.gray_500,
            lineHeight: "normal",
            fontSize: typeOfAg === "UX" ? "16px" : "18px",
            fontWeight: 700,
          }}
        >
          {defaultAgentList.map((item) => item.agentName).includes(typeOfAg) &&
            `${greeting} ${formattedName}, `}
          {(user?.userRole === "student" || user?.userRole === "tutor") &&
            formattedName}
          {
            [...agentList, ...defaultAgentList].find(
              (it) => it.agentName === typeOfAg
            )?.agentText
          }
        </Typography>

        {typeOfAg === "UX" && (
          <Box mt={2}>
            <Typography
              sx={{
                color: (theme) => theme.palette.grey.gray_500,
                fontSize: "16px",
                fontWeight: 700,
                mb: 2,
              }}
            >
              Some things I can help with:
            </Typography>
            {[
              `<b>Conduct competitive reviews</b>. Eg “Conduct a competitive review of the best consumer banking websites”`,
              `<b>Conduct a UX expert or heuristic review.</b> Eg. “Please conduct a UX expert review of www.apple.com"`,
              `<b>Accessibility audits.</b> Eg “Conduct an Accessibility audit of www.apple.com`,
              `<b>Create personas.</b> Eg “Create three personas for the www.apple.com website”`,
              `<b>Create interview questions.</b> Eg “Create interview questions to assess the UX of www.apple.com"`,
              `<b>Transcribe interviews.</b>  Upload an audio file and I will email you a transcript in a Word doc (max 25MB file size)`,
              `<b>Report writing and interview summaries.</b> Upload interview transcripts (in PDF format) and just ask me to write a UX report. Tell me the headings you want or let me work that out for you.`,
              `<b>Design critiques.</b> Upload a screenshot of your designs from Figma (or any other tool) and I can provide a design critique.`,
              `<b>UX Humor.</b> Eg “Tell me a UX joke”`,
              `<b>AMA.</b> Ask me anything!`,
            ].map((item) => (
              <Box sx={{ display: "flex", ml: 2 }}>
                <MuiListItemIcon>
                  <FiberManualRecordIcon sx={{ width: "10px" }} />
                </MuiListItemIcon>
                <Typography
                  sx={{
                    fontSize: "16px",
                    marginBottom: 2,
                    color: (theme) => theme.palette.grey.gray_500,
                  }}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PromptHolder;
